import { withPreviewResolver } from 'gatsby-source-prismic';
import * as React from 'react';

const PreviewPage = ({ isPreview, isLoading, previewData }) => {
  if (isPreview === false) return 'Not a preview!';

  return (
    <div className="flex justify-center items-center">
      <p>loading preview</p>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'percent-blog',
  linkResolver: ({ node, key, value }) => (doc) => {
    return `/blog/${doc.uid}`;
  },
});
